
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { mapState } from "vuex";
@Options({
  computed: {
    ...mapState(["modal"])
  }
})
export default class HardButton extends Vue {
  showModal() {
    store.dispatch("showModal");
  }
}
