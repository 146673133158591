
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({})
export default class Block extends Vue {
  @Prop(String) private readonly headerLink: string | undefined;
  @Prop(String) private readonly headerText: string | undefined;
  @Prop(String) private readonly header: string | undefined;
  @Prop(String) private readonly info: object | undefined;
}
