<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: TTFirs-Bold;
  color: #fff;
}
</style>
