
import { Options, Vue } from "vue-class-component";
import Logo from "./components/Logo/Logo.vue";
import Contact from "./components/Contact/Contact.vue";
import Text from "./components/Text/Text.vue";
import UnderText from "./components/UnderText/UnderText.vue";
@Options({
  components: {
    Logo,
    Contact,
    Text,
    UnderText
  }
})
export default class Header extends Vue {}
