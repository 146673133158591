
import { Options, Vue } from "vue-class-component";
import HardButton from "./components/HardButton/HardButton.vue";
import axios from 'axios';

@Options({
  components: {
    HardButton
  },
  data: () => {
    return {
      showWarning: false,
      showForm: true,
      showSuccess: false,
      showFailure: false,
      toggleCheckbox: true,
      form: {
        name: '',
        email: '',
        phone: ''
      }
    }
  },
  methods: {
    async sendMail() {
      if (this.form.name.length > 5 && this.form.email.length > 5 && this.form.phone.length > 8 && this.toggleCheckbox) {
        this.showWarning = false

        try {
          const response = await axios({
            method: 'POST',
            url: process.env.VUE_APP_API_URL + '/api/v1/send-mail',
            data: this.form
          });

          if (response.status === 200) {
            this.showForm = false;
            this.showSuccess = true;
          } else {
            this.showForm = false;
            this.showFailure = true;
          }
        } catch (err) {
          this.showForm = false;
          this.showFailure = true;
        }
      } else {
        this.showWarning = true;
      }
    }
  }
})
export default class Form extends Vue {}
