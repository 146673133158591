
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import Block from "./components/Block.vue";

@Options({
  components: {
    Swiper,
    SwiperSlide,
    Block
  },
  data() {
    return {
      blocks: {
        gkhMarket: {
          headerLink: 'gkh-market.ru',
          headerText: 'ЖКХ-Маркет — поставки товаров для ЖКХ и комплексное снабжение',
          header: 'Проект ЖКХ-Маркет',
          info: [
            {
              icon: 'fa-regular fa-window-maximize',
              text: '<strong>Сайт</strong><br />Перенос существующего проекта с 1С-Битрикс на платформу EasyOneWeb. Редизайн сайта. Онлайн данные по ценам, остаткам и движению заказов клиентов.'
            },
            {
              icon: 'fa-solid fa-server',
              text: '<strong>1С</strong><br />Интеграция модуля EasyOneWeb в 1С. Перенос 1С в облачную инфраструктуру Mail.Ru Cloud Solutions.'
            },
            {
              icon: 'fa-solid fa-ranking-star',
              text: '<strong>Маркетинг</strong><br />Реализация функционала маргентинговых инструментов для управления в 1С: маркетинговые группы товаров, фильтр, маркеры.'
            },
            {
              icon: 'fa-solid fa-store',
              text: '<strong>Маркетплейсы</strong><br />Интеграция проекта с ведущими маркетплейсами страны. Выгрузка и обновление карточек товаров, складские остатки, управления складами, цены.'
            },
            {
              icon: 'fa-regular fa-circle-question',
              text: '<strong>Поддержка</strong><br />Техническая поддержка проекта.'
            }
          ]
        },
        lilitnails: {
          headerLink: 'lilitnails.ru',
          headerText: 'Лилит — интернет-магазин профессиональной косметики',
          header: 'Проект Лилит',
          info: [
            {
              icon: 'fa-regular fa-window-maximize',
              text: '<strong>Сайт</strong><br />Разработка проекта на платформе EasyOneWeb. Дизайн сайта. Онлайн данные по ценам, остаткам и движению заказов клиентов. Особенно важным аспектом являются цены, так как стоимость товара у поставщиков может меняться несколько раз в день, а на долю интернет-магазина приходится большинство заказов. Платформа EasyOneWeb справляется с этой задачей превосходно!'
            },
            {
              icon: 'fa-solid fa-server',
              text: '<strong>1С</strong><br />Интеграция модуля EasyOneWeb в 1С. Сервер расположен локально.'
            },
            {
              icon: 'fa-solid fa-ranking-star',
              text: '<strong>Маркетинг</strong><br />Реализация функционала маргентинговых инструментов для управления в 1С: маркетинговые группы товаров, фильтр, маркеры, торговые предложения.'
            },
            // {
            //   icon: 'fa-solid fa-store',
            //   text: '<strong>Маркетплейсы</strong><br />Интеграция проекта с ведущими маркетплейсами страны. Выгрузка и обновление карточек товаров, складские остатки, управления складами, цены.'
            // },
            {
              icon: 'fa-regular fa-circle-question',
              text: '<strong>Поддержка</strong><br />Техническая поддержка проекта.'
            }
          ]
        },
        zumba24: {
          headerLink: 'zumba24.ru',
          headerText: 'ZUMBA24 — маркетплейс семян для посева огорода',
          header: 'Проект ZUMBA24',
          info: [
            {
              icon: 'fa-regular fa-window-maximize',
              text: '<strong>Сайт</strong><br />Разработка проекта на платформе EasyOneWeb. Дизайн сайта. Онлайн данные по ценам, остаткам и движению заказов клиентов. Интеграция скидочной системы по промокодам. Интеграция с эквайринговой системой для онлайн оплаты заказов. Интеграция с транспортыми компаниями для расчёта стоимости заказов в реальном времени.'
            },
            {
              icon: 'fa-solid fa-server',
              text: '<strong>1С</strong><br />Интеграция модуля EasyOneWeb в 1С. Проработка серверной архитектуры проекта. Настройка и администрирование серверов проекта в облачной инфраструктуре Яндекс.Облако.'
            },
            {
              icon: 'fa-solid fa-ranking-star',
              text: '<strong>Маркетинг</strong><br />Реализация функционала маргентинговых инструментов для управления в 1С: маркетинговые группы товаров, фильтр.'
            },
            {
              icon: 'fa-solid fa-store',
              text: '<strong>Маркетплейсы</strong><br />Интеграция проекта с ведущими маркетплейсами страны. Выгрузка и обновление карточек товаров, складские остатки, управления складами, цены, управление торговыми предложениями.'
            },
            {
              icon: 'fa-regular fa-circle-question',
              text: '<strong>Поддержка</strong><br />Техническая поддержка проекта.'
            }
          ]
        }
      }
    }
  }
})
export default class Carusel extends Vue {}
