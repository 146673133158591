show<template>
  <div class="block1">
    <div class="block1-main">
      <div class="block1-text">{{ title }}</div>
      <div class="block1-list">
        <ul class="_row">
          <li v-for="infoData in info" :key="infoData" class="_col-12 _col-lg-6">{{ infoData }}</li>
        </ul>

        <div class="description" v-html="description"></div>
      </div>
      <div v-if="showHint" class="block1-hint">
        почему цена от <img src="@/assets/svg/hint.svg" />
        <div class="block1-hint-message">
          <div class="block1-hint-message-text">
            Стоимость Проекта зависит от Технического Задания и аудита Ваших бизнес процессов в 1С
          </div>
        </div>
        <div class="block1-hint-message-skew"></div>
      </div>
      <div class="block1-price">
        <div class="block1-price-new">{{ price }}</div>
        <div v-if="oldPrice.length > 0" class="block1-price-old">{{ oldPrice }}</div>
      </div>
    </div>
    <div v-if="priceInfo.length > 0" class="block1-footer">
      <img src="@/assets/svg/persent.svg" />
      {{ priceInfo }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    info: Array,
    showHint: Boolean,
    price: String,
    oldPrice: String,
    priceInfo: String,
    description: String
  }
}
</script>

<style lang="scss" scoped>
.block {
  &s {
    padding-top: 60px;
    display: flex;
  }
  &1 {
    height: 100%;
    position: relative;
    &-main {
      width: 100%;
      border: 1px solid #fff;
      box-sizing: border-box;
      padding: 60px 60px 44px;
      box-sizing: border-box;
    }
    &-text {
      font-family: TTFirs-Bold;
      font-size: 48px;
      line-height: 125%;
      letter-spacing: 0.06em;
      color: #ffffff;
    }
    &-list {
      & ul {
        column-count: 2;
        padding: 0px;

        & li {
          position: relative;
          padding: 5px 15px 5px 20px;
          list-style-type: none;
          font-family: TTFirs-Medium;
          font-size: 20px;
          line-height: 150%;
        }
        & li:before {
          position: absolute;
          top: 5px;
          left: 0;
          content: "~";
          color: #ff6f43;
        }
      }
    }
    &-hint {
      position: relative;
      padding-top: 35px;
      font-family: TTFirs-Medium;
      font-size: 14px;
      line-height: 150%;
      text-decoration-line: underline;
      color: #6d73ff;
      display: inline-flex;
      &:hover {
        cursor: pointer;
        & .block1-hint-message,
        .block1-hint-message-skew {
          display: block;
        }
      }
      & img {
        padding-left: 5px;
      }
      &-message {
        position: absolute;
        top: -82px;
        left: 0;
        display: none;
        z-index: 50;
        &-text {
          width: 240px;
          height: 64px;
          background: #ffffff;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-family: TTFirs-Medium;
          font-size: 14px;
          line-height: 125%;
          color: #19181b;
          padding-left: 12px;
          padding-right: 10px;
          padding-top: 14px;
          box-sizing: border-box;
          background-color: #fff;
          overflow: visible;
        }
        &-skew {
          position: absolute;
          top: -40px;
          left: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: #fff;
          width: 240px;
          height: 50px;
          transform: skew(0deg, -10deg);
          display: none;
        }
      }
    }
    &-price {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      &-new {
        font-family: TTFirs-Bold;
        font-size: 36px;
        line-height: 125%;
        letter-spacing: 0.06em;
        color: #ffffff;
        padding-right: 30px;
      }
      &-old {
        font-family: TTFirs-Bold;
        font-size: 36px;
        line-height: 125%;
        letter-spacing: 0.06em;
        text-decoration-line: line-through;
        color: #ffffff;
        opacity: 0.2;
      }
    }
    &-footer {
      display: flex;
      min-height: 71px;
      padding: 15px;
      border-radius: 0 0 40px 40px;
      background-color: #ff6f43;
      font-family: TTFirs-Bold;
      font-size: 20px;
      line-height: 150%;
      color: #19181b;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      & img {
        padding-right: 30px;
      }
    }
  }
}
@media (max-width: 960px){
  .block1-main{
    padding: 40px
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .block1-text {
    font-size: 32px;
  }
  .block1-price {
    display: block;
  }
  .block1-price-new {
    font-size: 24px;
  }
  .block1-price-old {
    font-size: 24px;
  }
}
</style>
