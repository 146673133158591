
import { Options, Vue } from "vue-class-component";
import Logo from "@/components/Header/components/Logo/Logo.vue";
import HardButton from "./components/HardButton/HardButton.vue";

@Options({
  components: {
    Logo,
    HardButton
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
})
export default class Footer extends Vue {}
