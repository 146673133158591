
import { Options, Vue } from "vue-class-component";

import HardButton from "./components/HardButton/HardButton.vue";
import Chip from "./components/Chip/Chip.vue";
@Options({
  components: {
    HardButton,
    Chip
  }
})
export default class UnderText extends Vue {}
