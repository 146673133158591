import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faWindowMaximize,
  faCircleQuestion
} from '@fortawesome/free-regular-svg-icons';
import {
  faServer,
  faRankingStar,
  faStore
} from '@fortawesome/free-solid-svg-icons';

library.add(faWindowMaximize);
library.add(faCircleQuestion);
library.add(faServer);
library.add(faRankingStar);
library.add(faStore);


import "@/assets/global.scss"


createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount("#app");

