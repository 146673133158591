
import { Options, Vue } from "vue-class-component";
import Spec from './components/Spec.vue';
import HardButton from "./components/HardButton/HardButton.vue";
import axios from 'axios';

@Options({
  components: {
    Spec,
    HardButton
  },
  data: () => {
    return {
      showWarning: false,
      showForm: true,
      showSuccess: false,
      showFailure: false,
      toggleCheckbox: false,
      form: {
        name: '',
        email: '',
        phone: ''
      },
      infoForSpecs: {
        platform: {
          title: 'Платформа EasyOneWeb',
          info: [
            'Дистрибутив EasyOneWeb, версия 2.0',
            'Модуль для 1С',
            'Документация для разработчика по платформе и ее API',
            'Консультация технического отдела по работе платформы (до 10 часов)'
          ],
          showHint: false,
          price: '50 000 рублей',
          oldPrice: '',
          priceInfo: '',
          description: `
            <div>
              <p>
                Платформа позволяет:
                <ul>
                  <li>реализация шаблонов на EJS или на любом frontend фреймворке (REST API)</li>
                  <li>вывод онлайн каталога номенклатуры на сайте</li>
                  <li>функционал корзины и оформления заказа напрямую в 1С</li>
                  <li>модуль регистрации и аутентификации пользователей</li>
                  <li>личный кабинет пользователя</li>
                  <li>фильтр товаров по свойствам</li>
                </ul>
              </p>

              <p>
                Для реализации проекта на платформе Вам потребуются специалисты: 1С разработчик, веб разработчик javascript (NodeJS).
              </p>
            </div>
          `
        },
        ecommerce: {
          title: 'Интернет-магазин под ключ',
          info: [
            'Дистрибутив EasyOneWeb, версия 2.0',
            'Модуль для 1С',
            'Документация для разработчика по платформе и ее API',
            'Консультация технического отдела по работе платформы (до 10 часов)',
            'Аудит 1С по данным',
            'Индивидуальный дизайн для проекта',
            'Подготовка инфраструктуры и настройка веб-сервера под проект и платформу',
            'Разработка интернет-магазина на платформе EasyOneWeb',
            'Техническая поддержка проекта 3 месяца'
          ],
          showHint: false,
          price: '400 000 рублей',
          oldPrice: '',
          priceInfo: '',
          description: `
            <div>
              <p>
                Дополнительная информация
                <ul>
                  <li>Срок разработки в среднем составляет от 1 до 3 месяцев</li>
                  <li>По итогу интеграции и разработки Вы получаете готовый интернет-магазин с бесшовной интеграцией в 1С</li>
                </ul>
              </p>
            </div>
          `
        },
        originalProject: {
          title: 'Индивидуальный проект',
          info: [
            'Дистрибутив EasyOneWeb, версия 2.0',
            'Модуль для 1С',
            'Документация для разработчика по платформе и ее API',
            'Консультация технического отдела по работе платформы (до 10 часов)',
            'Аудит 1С по данным',
            'Индивидуальный дизайн для проекта',
            'Подготовка инфраструктуры и настройка веб-сервера под проект и платформу',
            'Разработка интернет-магазина на платформе EasyOneWeb',
            'Разработка функционала под уникальные требования заказчика',
            'Интеграция с ведущими маркетплейсами — OZON, Wildberries, SberMegaMarket и Yandex.Market',
            'Техническая поддержка проекта 3 месяца'
          ],
          showHint: true,
          price: 'От 600 000 рублей',
          oldPrice: 'От 750 000 рублей',
          priceInfo: 'Скидка 20% при заказе индивидуального проекта!',
          description: `
            <div>
              <p>
                Дополнительная информация
                <ul>
                  <li>Срок разработки в среднем составляет от 2 месяцев</li>
                  <li>По итогу интеграции и разработки Вы получаете готовый интернет-магазин с бесшовной интеграцией в 1С</li>
                  <li>Дополнительно разрабатываем необходимый индивидуальный функционал для Вашего бизнеса</li>
                  <li>Вы получаете готовую интеграцию с ведущими маркетплейсами страны напрямую в 1С, с учётом специфики Вашей деятельности</li>
                </ul>
              </p>
            </div>
          `
        },
        martkeplaces: {
          title: 'Интеграция с маркетплейсами',
          info: [
            'Интеграция номенклатуры и заказов с маркетплейсами — OZON, Wildberries, SberMegaMarket и Yandex.Market',
            'Техническая поддержка 2 месяца'
          ],
          showHint: true,
          price: 'От 100 000 рублей',
          oldPrice: '',
          priceInfo: '',
          description: `
            <div>
              <p>
                Дополнительная информация
                <ul>
                  <li>Срок интеграции в среднем составляет от 1 месяца</li>
                  <li>Вы получаете готовую интеграцию с ведущими маркетплейсами страны напрямую с 1С, с учётом специфики Вашей деятельности</li>
                </ul>
              </p>
            </div>
          `
        }
      }
    }
  },
  methods: {
    async sendMail() {
      if (this.form.name.length > 5 && this.form.email.length > 5 && this.form.phone.length > 8 && this.toggleCheckbox) {
        this.showWarning = false

        try {
          const response = await axios({
            method: 'POST',
            url: process.env.VUE_APP_API_URL + '/api/v1/send-mail',
            data: this.form
          });

          if (response.status === 200) {
            this.showForm = false;
            this.showSuccess = true;
          } else {
            this.showForm = false;
            this.showFailure = true;
          }
        } catch (err) {
          this.showForm = false;
          this.showFailure = true;
        }
      } else {
        this.showWarning = true;
      }
    }
  }
})
export default class Block4 extends Vue {
  private toggleCheckbox = false;
}
